// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
@import "./scss/style.scss";

.react-datepicker-wrapper {
  display: block !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(1000px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(1000px);
}
.findTableTd {
  padding: 0.25rem !important;
  cursor: pointer;
}
.closeFindButton {
  height: 30px;
  width: 30px;
  padding: 0;
}
.dateIsInvalid {
  border-color: #f86c6b;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.cursor-pointer {
  cursor: pointer;
}

.checkbox_form-control {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0 0.75rem !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.list_image {
  width: 75;
  height: 75px;
}

.view_image {
  max-width: 350px;
  max-height: 350px;
}
.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  //min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}
